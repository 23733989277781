import(/* webpackMode: "eager" */ "/var/www/mangahentai.rf/build/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/var/www/mangahentai.rf/build/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/var/www/mangahentai.rf/build/public/images/promotions/upload-cover-preview.jpg");
;
import(/* webpackMode: "eager", webpackExports: ["BookGrid"] */ "/var/www/mangahentai.rf/build/src/app/ui/common/BookGrid/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BookImage"] */ "/var/www/mangahentai.rf/build/src/app/ui/common/BookImage/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/mangahentai.rf/build/src/app/ui/common/card/HorizontalScroll/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SliderCard"] */ "/var/www/mangahentai.rf/build/src/app/ui/common/card/SliderCard/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ForbidenGenresBlock"] */ "/var/www/mangahentai.rf/build/src/app/ui/common/ForbiddenGenresBlock/index.tsx");
